import * as styles from './scroll-to-top-button.module.scss';

import React, { useEffect, useState } from 'react';

import UpArrow from '-!svg-react-loader!../../assets/up-arrow.svg';
import clsx from 'clsx';
import useWindowPosition from '../../hooks/useWindowPosition';

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  const scrollPosition = useWindowPosition();

  useEffect(() => {
    if (scrollPosition > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [scrollPosition]);

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className={clsx(styles.backToTopButton, showButton && styles.visible)}
      onClick={backToTop}
      aria-label="Torna in cima alla pagina"
    >
      <UpArrow className={styles.arrowIcon} />
    </button>
  );
};

export default ScrollToTopButton;
